import React, { useEffect, useState } from "react";
import { Word, WordListPerCharacter } from "../types/index";

type Props = {
  fileName: string;
  words: WordListPerCharacter;
  filter?: string;
  wordSelected: (event: React.FormEvent<HTMLSelectElement>) => void;
};

const WordList = ({ fileName, words, filter, wordSelected }: Props) => {
  const [showAllWords, setShowAllWords] = useState(false);
  const [temporaryFilter, setTemporaryFilter] = useState(filter ?? "");
  const [filterOnlyBeginning, setFilterOnlyBeginning] = useState(false);

  useEffect(() => {
    if (filter) {
      setTemporaryFilter(filter);
    }
  }, [filter, setTemporaryFilter]);

  const setFilterCharacter = (index: number, character: string) => {
    let newFilter = temporaryFilter.split("");

    if (character.length === 1) {
      newFilter[index] = character.toUpperCase();
    } else {
      newFilter[index] = "-";
    }

    setTemporaryFilter(newFilter.join(""));
  };

  let wordToRegex: (word: string) => string;

  if (filterOnlyBeginning) {
    wordToRegex = (word) => word.replaceAll(/-*$/g, "").replaceAll("-", ".{1}");
  } else {
    wordToRegex = (word) => word.replaceAll("-", ".{1}");
  }

  const filterWords = (wordList: WordListPerCharacter): Word[] => {
    if (showAllWords) {
      return Object.values(wordList)
        .reduce(
          (accumulator: Word[], words: Word[]) => accumulator.concat(words),
          []
        )
        .sort();
    } else if (temporaryFilter) {
      if (filterOnlyBeginning) {
        return Object.values(wordList)
          .reduce((accumulator, words) => accumulator.concat(words), [])
          .filter((word) =>
            new RegExp("^" + wordToRegex(temporaryFilter)).test(word)
          );
      } else if (wordList[temporaryFilter.length]) {
        return wordList[temporaryFilter.length].filter((word) =>
          new RegExp("^" + wordToRegex(temporaryFilter) + "$").test(word)
        );
      }
    }

    return [];
  };

  return (
    <>
      <div>
        <small>{fileName}</small>
      </div>
      <div>
        {(temporaryFilter || "").split("").map((character, index) => (
          <input
            key={index}
            type="text"
            value={character}
            style={{ width: "1rem" }}
            onChange={(event) => setFilterCharacter(index, event.target.value)}
          />
        ))}
      </div>
      {temporaryFilter && filter && (
        <div>
          <button onClick={() => setTemporaryFilter(filter)}>
            Reset filter
          </button>
        </div>
      )}
      <select size={15} onChange={wordSelected}>
        {filterWords(words).map((word: Word) => (
          <option key={word} value={word}>
            {word}
          </option>
        ))}
      </select>
      <button onClick={() => setShowAllWords(!showAllWords)}>
        {showAllWords ? "Show applicable words" : "Show all words"}
      </button>
      <button onClick={() => setFilterOnlyBeginning(!filterOnlyBeginning)}>
        {filterOnlyBeginning ? "Filter exact" : "Filter only beginning"}
      </button>
    </>
  );
};

export default WordList;
