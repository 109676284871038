import React, { useState } from "react";
import { HotKeys } from "react-hotkeys-ce";
import CrosswordSvg from "./CrosswordSvg";
import { keyMap, keyHandlers, isCharacterKeyPress } from "../models/keys";
import Cell from "../models/cell";
import GridPosition from "../models/gridPosition";
import {
  Coordinate,
  Crossword,
  Mode,
  Cell as CellAsType,
  CellType,
  ClueDirection,
  WRITING_DIRECTION,
} from "../types/index";

const OUTLINE_WIDTH = 3;

type Props = {
  setData: (data: Crossword) => void;
  data: Crossword;
  openDetails: (column: number, row: number) => void;
  currentCellPosition: Coordinate;
  emptyCoordinates: Coordinate[];
  lonelyCoordinates: Coordinate[];
  requestedMoveToCell: (column: number, row: number) => void;
  mode: Mode;
  MODE: typeof Mode;
  setMode: (mode: Mode) => void;
  cellWidth: number;
  isValidCell: (column: number, row: number) => CellAsType;
};

const CrosswordGrid = ({
  setData,
  data,
  openDetails,
  currentCellPosition,
  emptyCoordinates,
  lonelyCoordinates,
  requestedMoveToCell,
  mode,
  MODE,
  setMode,
  cellWidth,
  isValidCell,
}: Props) => {
  const [writingDirection, setWritingDirection] = useState(
    WRITING_DIRECTION.horizontal
  );
  const currentCell = new Cell(data, setData, currentCellPosition);

  const getPositionByDirection = (
    step: number,
    direction: WRITING_DIRECTION
  ): Coordinate => {
    switch (direction) {
      case WRITING_DIRECTION.horizontal:
        return [currentCellPosition[0] + step, currentCellPosition[1]];
      case WRITING_DIRECTION.vertical:
        return [currentCellPosition[0], currentCellPosition[1] + step];
    }
  };
  const moveCurrentCellInWritingDirection = (step: number) => {
    switch (writingDirection) {
      case WRITING_DIRECTION.horizontal: {
        const nextPosition = new GridPosition(data, currentCellPosition);

        if (
          nextPosition.move(step, 0) &&
          nextPosition.cellType === "character"
        ) {
          moveCurrentCell(step, 0);
        }
        break;
      }
      case WRITING_DIRECTION.vertical: {
        const nextPosition = new GridPosition(data, currentCellPosition);

        if (
          nextPosition.move(0, step) &&
          nextPosition.cellType === "character"
        ) {
          moveCurrentCell(0, step);
        }
        break;
      }
    }
  };
  const moveCurrentCell = (columns: number, rows: number) => {
    requestedMoveToCell(
      currentCellPosition[0] + columns,
      currentCellPosition[1] + rows
    );
  };

  const toggleWritingDirectionRequested = () => {
    setWritingDirection(
      writingDirection === WRITING_DIRECTION.horizontal
        ? WRITING_DIRECTION.vertical
        : WRITING_DIRECTION.horizontal
    );
  };

  const changeTypeRequested = (type: CellType) => (currentCell.type = type);

  const changeDirectionRequested = (direction: ClueDirection) =>
    (currentCell.direction = direction);

  const moveCurrentCellRequested = (dx: number, dy: number) => {
    moveCurrentCell(dx, dy);
  };

  const removeCharacterRequested = () => {
    const currentCellData =
      data[currentCellPosition[1]][currentCellPosition[0]];

    switch (currentCellData.type) {
      case "character":
        if (currentCellData.content && currentCellData.content.length > 0) {
          currentCell.removeLastCharacter();
          moveCurrentCellInWritingDirection(-1);
        } else {
          const previousCellPosition = getPositionByDirection(
            -1,
            writingDirection
          );

          if (isValidCell(previousCellPosition[0], previousCellPosition[1])) {
            const previousCell = new Cell(data, setData, previousCellPosition);

            if (previousCell.type === "character") {
              previousCell.removeLastCharacter();
              moveCurrentCellInWritingDirection(-1);
            }
          }
        }
        break;
      case "clue":
        currentCell.removeLastCharacter();
        break;
    }
  };

  const changeModeRequested = (mode: Mode) => setMode(mode);

  return (
    //@ts-ignore
    <HotKeys
      keyMap={keyMap}
      handlers={keyHandlers(
        currentCellPosition,
        mode,
        MODE,
        changeTypeRequested,
        changeDirectionRequested,
        moveCurrentCellRequested,
        toggleWritingDirectionRequested,
        removeCharacterRequested,
        changeModeRequested
      )}
      allowChanges={true}
    >
      <div
        tabIndex={0}
        onKeyPress={(event) => {
          const currentCellData =
            data[currentCellPosition[1]][currentCellPosition[0]];

          if (event.code !== "Enter" && mode === MODE.Input) {
            switch (currentCellData.type) {
              case "character":
                if (isCharacterKeyPress(event)) {
                  currentCell.appendContent(event.key);
                  moveCurrentCellInWritingDirection(1);
                }
                break;
              case "clue":
                if (isCharacterKeyPress(event)) {
                  event.preventDefault();
                  currentCell.appendContent(event.key);
                }
                break;
            }
          }
        }}
      >
        <CrosswordSvg
          data={data}
          cellWidth={cellWidth}
          OUTLINE_WIDTH={OUTLINE_WIDTH}
          emptyCoordinates={emptyCoordinates}
          lonelyCoordinates={lonelyCoordinates}
          currentCell={currentCellPosition}
          writingDirection={writingDirection}
          openDetails={openDetails}
          print={false}
          includeSolution={true}
        />
      </div>
    </HotKeys>
  );
};

export default CrosswordGrid;
