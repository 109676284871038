import React, { useRef } from "react";

type Props = {
  columns: number;
  rows: number;
  gridResizeRequested: (columns: number, rows: number) => void;
  cellWidth: number;
  setCellWidth: (cellWidth: number) => void;
};

const Settings = ({
  columns,
  rows,
  gridResizeRequested,
  cellWidth,
  setCellWidth,
}: Props) => {
  const columnsInput = useRef<HTMLInputElement>(null);
  const rowsInput = useRef<HTMLInputElement>(null);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div>
        Grid: <input type="number" defaultValue={columns} ref={columnsInput} />{" "}
        x <input type="number" defaultValue={rows} ref={rowsInput} />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <button
          onClick={() => {
            if (columnsInput.current && rowsInput.current) {
              gridResizeRequested(
                parseInt(columnsInput.current.value),
                parseInt(rowsInput.current.value)
              );
            }
          }}
        >
          Resize
        </button>
      </div>
      <div>
        Cell width:{" "}
        <input
          type="number"
          defaultValue={cellWidth}
          onInput={(event) => setCellWidth(parseInt(event.currentTarget.value))}
        />
      </div>
    </div>
  );
};

export default Settings;
