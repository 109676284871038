import React from "react";
import { Coordinate, WRITING_DIRECTION } from "../types/index";
import { ArrowDown, ArrowRight } from "./Arrows";

type Props = {
  currentCell: Coordinate;
  writingDirection: WRITING_DIRECTION;
  cellWidth: number;
  OUTLINE_WIDTH: number;
};

const WritingDirectionIndicator = ({
  currentCell,
  writingDirection,
  cellWidth,
  OUTLINE_WIDTH,
}: Props) => {
  switch (writingDirection) {
    case WRITING_DIRECTION.horizontal:
      return (
        <ArrowRight
          startArrowX={(currentCell[0] + 1) * cellWidth + 2 * OUTLINE_WIDTH}
          startArrowY={
            currentCell[1] * cellWidth + cellWidth / 2 + OUTLINE_WIDTH
          }
          fill="#3333CC"
          fillOpacity={0.5}
        />
      );
    case WRITING_DIRECTION.vertical:
      return (
        <ArrowDown
          startArrowX={
            currentCell[0] * cellWidth + cellWidth / 2 + OUTLINE_WIDTH
          }
          startArrowY={(currentCell[1] + 1) * cellWidth + 2 * OUTLINE_WIDTH}
          fill="#3333CC"
          fillOpacity={0.5}
        />
      );
    default:
      return null;
  }
};

export default WritingDirectionIndicator;
