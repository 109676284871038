import React, { useRef } from "react";
import FileIcon from "../assets/FileIcon";
import { Crossword } from "../types/index";

type Props = {
  dataLoaded: (fileName: string, data: Crossword) => void;
};

const Load = ({ dataLoaded }: Props) => {
  const load = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = loadData(file.name, reader);
    reader.readAsText(file);
  };
  const loadData = (fileName: string, reader: FileReader) => () => {
    const fileContent = reader.result;

    if (fileContent && typeof fileContent === "string") {
      const data = JSON.parse(fileContent);
      dataLoaded(fileName, data);
    } else {
      console.error(
        "Could not load file. Reading file returned something other than a string."
      );
    }
  };

  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <button
        title="Load crossword"
        onClick={() => fileInput.current && fileInput.current.click()}
      >
        <FileIcon />
      </button>
      <input
        type="file"
        onChange={(event) =>
          event.target.files &&
          event.target.files[0] &&
          load(event.target.files[0])
        }
        style={{ display: "none" }}
        ref={fileInput}
      />
    </>
  );
};

export default Load;
