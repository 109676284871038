import React from "react";
import { WordLists as WordListsType } from "../types/index";
import WordList from "./WordList";

type Props = {
  wordLists: WordListsType;
  filter?: string;
  wordSelected: (event: React.FormEvent<HTMLSelectElement>) => void;
};

const WordLists = ({ wordLists, filter, wordSelected }: Props) => (
  <>
    {Object.entries(wordLists).map(([fileName, words]) => (
      <WordList
        key={fileName}
        fileName={fileName}
        words={words}
        filter={filter}
        wordSelected={wordSelected}
      />
    ))}
  </>
);

export default WordLists;
