import React from "react";
import ReactDOM from "react-dom";

import App from "./components/App";

import "./css/main.scss";

window.addEventListener("beforeunload", (event) => {
  var confirmationMessage =
    "Do you really want to leave? Any unsaved changes will be lost.";

  event.preventDefault();

  (event || window.event).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Webkit, Safari, Chrome
});

ReactDOM.render(<App />, document.getElementById("app"));
