import { CellType, ClueDirection, Coordinate, Mode } from "../types/index";

const keyMap = {
  CHANGE_TYPE_TO_EMPTY: "e",
  CHANGE_TYPE_TO_CHARACTER: "w",
  CHANGE_TYPE_TO_CLUE: "r",
  CHANGE_DIRECTION_TO_EAST: "p",
  CHANGE_DIRECTION_TO_SOUTH: "u",
  CHANGE_DIRECTION_TO_EASTDOWN: "o",
  CHANGE_DIRECTION_TO_WESTDOWN: "y",
  CHANGE_DIRECTION_TO_SOUTHEAST: "n",
  CHANGE_DIRECTION_TO_NORTHEAST: "m",
  MOVE_UP: ["k", "up"],
  MOVE_DOWN: ["j", "down"],
  MOVE_RIGHT: ["l", "right"],
  MOVE_LEFT: ["h", "left"],
  TOGGLE_WRITING_DIRECTION: "d",
  REMOVE_CHARACTER: "backspace",
  ENTER_INPUT_MODE: { sequence: "i", action: "keypress" },
  ENTER_COMMAND_MODE: "escape",
};

const keyHandlers = (
  currentCellPosition: Coordinate,
  mode: Mode,
  MODE: typeof Mode,
  changeTypeRequested: (cellType: CellType) => CellType,
  changeDirectionRequested: (direction: ClueDirection) => string,
  moveCurrentCellRequested: (dx: number, dy: number) => void,
  toggleWritingDirectionRequested: () => void,
  removeCharacterRequested: () => void,
  changeModeRequested: (mode: Mode) => void
) => ({
  CHANGE_TYPE_TO_EMPTY: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeTypeRequested("empty");
  },
  CHANGE_TYPE_TO_CHARACTER: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeTypeRequested("character");
  },
  CHANGE_TYPE_TO_CLUE: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeTypeRequested("clue");
  },
  CHANGE_DIRECTION_TO_EAST: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("e");
  },
  CHANGE_DIRECTION_TO_SOUTH: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("s");
  },
  CHANGE_DIRECTION_TO_EASTDOWN: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("ed");
  },
  CHANGE_DIRECTION_TO_WESTDOWN: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("wd");
  },
  CHANGE_DIRECTION_TO_SOUTHEAST: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("se");
  },
  CHANGE_DIRECTION_TO_NORTHEAST: () => {
    if (currentCellPosition && mode === MODE.Command)
      changeDirectionRequested("ne");
  },
  MOVE_UP: () => {
    if (currentCellPosition && mode === MODE.Command)
      moveCurrentCellRequested(0, -1);
  },
  MOVE_DOWN: () => {
    if (currentCellPosition && mode === MODE.Command)
      moveCurrentCellRequested(0, 1);
  },
  MOVE_LEFT: () => {
    if (currentCellPosition && mode === MODE.Command)
      moveCurrentCellRequested(-1, 0);
  },
  MOVE_RIGHT: () => {
    if (currentCellPosition && mode === MODE.Command)
      moveCurrentCellRequested(1, 0);
  },
  TOGGLE_WRITING_DIRECTION: () => {
    if (mode === MODE.Command) {
      toggleWritingDirectionRequested();
    }
  },
  REMOVE_CHARACTER: () => removeCharacterRequested(),
  ENTER_INPUT_MODE: (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    changeModeRequested(MODE.Input);
  },
  ENTER_COMMAND_MODE: () => changeModeRequested(MODE.Command),
});

const isCharacterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (typeof event.which == "undefined") {
    // This is IE, which only fires keypress events for printable keys
    return true;
  } else if (typeof event.which == "number" && event.which > 0) {
    // In other browsers except old versions of WebKit, event.which is
    // only greater than zero if the keypress is a printable key.
    // We need to filter out backspace and ctrl/alt/meta key combinations
    return (
      !event.ctrlKey && !event.metaKey && !event.altKey && event.which != 8
    );
  }
  return false;
};

export { keyMap, keyHandlers, isCharacterKeyPress };
