import { Cell, Crossword } from "../types/index";

export const createCell = (): Cell => ({ type: "character" });

const createRow = <CellType>(columns: number, cellCreator: () => CellType) =>
  new Array(columns).fill(null).map(() => cellCreator());

export const createGrid = <CellType = Cell>(
  columns: number,
  rows: number,
  cellCreator: () => CellType
): CellType[][] => {
  let data = new Array(rows).fill(null);
  return data.map((_row) => createRow(columns, cellCreator));
};

export const resizeGrid = (
  data: Crossword,
  setData: (data: Crossword) => void,
  columns: number,
  rows: number
) => {
  let newData = [...data];

  if (rows > getRows(data)) {
    const newRows = rows - getRows(data);

    for (let newRow = 0; newRow < newRows; ++newRow) {
      newData.push(createRow(columns, createCell));
    }
  } else if (rows < getRows(data)) {
    newData = newData.slice(0, rows);
  }

  newData = newData.map((row) => {
    if (columns > row.length) {
      const newColumns = columns - row.length;

      for (let newColumn = 0; newColumn < newColumns; ++newColumn) {
        row.push(createCell());
      }

      return row;
    } else if (columns < row.length) {
      return row.slice(0, columns);
    } else {
      return row;
    }
  });

  setData(newData);
};

export const getColumns = (data: Crossword) => data[0].length;
export const getRows = (data: Crossword) => data.length;
