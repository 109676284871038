import React from "react";

type Props = {
  data: string;
  Icon: JSX.Element;
  filename: string;
};

const SaveButton = ({ data, Icon, filename }: Props) => {
  const save = () => {
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
  };

  return (
    <button onClick={() => save()} title="Save crossword">
      {Icon}
    </button>
  );
};

export default SaveButton;
