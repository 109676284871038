import React from "react";

const MapMarker3 = ({ size = 24, color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11.5" cy="8.5" r="5.5" />
    <path d="M11.5 14v7" />
  </svg>
);

export default MapMarker3;
