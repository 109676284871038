import React from "react";

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  backgroundColor: string;
};

const CellGrid = ({ x, y, width, height, backgroundColor }: Props) => (
  <path
    d={`M ${x},${y} L ${x + width},${y} L ${x + width},${y + height} L ${x},${
      y + height
    } L ${x},${y}`}
    fill={backgroundColor}
    stroke="black"
    strokeWidth="1"
    shapeRendering="crispEdges"
  />
);

export default CellGrid;
