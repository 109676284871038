import React from "react";
import ReactDOMServer from "react-dom/server";
import Download from "../assets/Download";
import HelpCircle from "../assets/HelpCircle";
import MapMarker3 from "../assets/MapMarker3";
import Printer from "../assets/Printer";
import SlidersIcon from "../assets/SlidersIcon";
import Square from "../assets/Square";
import { Cell, Coordinate, Crossword, Mode, Word } from "../types/index";
import CrosswordCellDetails from "./CrosswordCellDetails";
import CrosswordSvg from "./CrosswordSvg";
import Load from "./Load";
import SaveButton from "./SaveButton";
import UploadWordList from "./UploadWordList";

type Props = {
  setShowSettings: (showSettings: boolean) => void;
  showSettings: boolean;
  setKeyboardShortcuts: (keyboardShortcuts: boolean) => void;
  keyboardShortcuts: boolean;
  currentCell: Coordinate;
  mode: Mode;
  data: Crossword;
  updateCell: (position: Coordinate, newCell: Cell) => void;
  currentWord: string;
  addWordlist: (fileName: string, words: Word[]) => void;
  setHighlightEmpty: (highlightEmpty: boolean) => void;
  setHighlightLonely: (highlightLonely: boolean) => void;
  setData: (data: Crossword) => void;
  cellWidth: number;
  openDetails: (column: number, row: number) => void;
  highlightEmpty: boolean;
  highlightLonely: boolean;
};

const Header = ({
  setShowSettings,
  showSettings,
  setKeyboardShortcuts,
  keyboardShortcuts,
  currentCell,
  mode,
  data,
  updateCell,
  currentWord,
  addWordlist,
  setHighlightEmpty,
  setHighlightLonely,
  setData,
  cellWidth,
  openDetails,
  highlightEmpty,
  highlightLonely,
}: Props) => (
  <header
    className="no-print"
    style={{
      width: "100%",
      backgroundColor: "#eeeeee",
      padding: "0.5rem",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      zIndex: 9999,
    }}
  >
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <button
            title="Settings"
            onClick={() => setShowSettings(!showSettings)}
          >
            <SlidersIcon />
          </button>
        </div>
        <div>
          <button onClick={() => setKeyboardShortcuts(!keyboardShortcuts)}>
            <HelpCircle />
          </button>
        </div>
        <div>{mode}</div>
      </div>
    </div>
    <div>
      {currentCell && (
        <CrosswordCellDetails
          cell={data[currentCell[1]][currentCell[0]]}
          position={currentCell}
          updateCell={updateCell}
          word={currentWord}
        />
      )}
    </div>
    <div style={{ display: "flex" }}>
      <div>
        <UploadWordList addWordlist={addWordlist} />
      </div>
      <div title="Highlight empty cells">
        <input
          type="checkbox"
          onClick={() => setHighlightEmpty(!highlightEmpty)}
        />{" "}
        <Square />
      </div>
      <div title="Highlight lonely cells">
        <input
          type="checkbox"
          onClick={() => setHighlightLonely(!highlightLonely)}
        />{" "}
        <MapMarker3 />
      </div>
    </div>
    <div style={{ display: "flex" }}>
      <div>
        <Load
          dataLoaded={(_fileName: string, data: Crossword) => setData(data)}
        />
      </div>
      <div style={{ marginRight: "1rem" }}>
        <SaveButton
          data={JSON.stringify(data)}
          Icon={<Download />}
          filename="crossword.json"
        />
      </div>
      <div>
        <SaveButton
          data={ReactDOMServer.renderToString(
            <CrosswordSvg
              data={data}
              cellWidth={cellWidth}
              OUTLINE_WIDTH={0}
              emptyCoordinates={[]}
              lonelyCoordinates={[]}
              currentCell={currentCell}
              openDetails={openDetails}
              print={true}
              includeSolution={false}
            />
          )}
          Icon={<Printer />}
          filename="crossword.svg"
        />
      </div>
    </div>
  </header>
);

export default Header;
