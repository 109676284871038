import {
  Coordinate,
  Crossword,
  Cell as CellAsType,
  ClueDirection,
} from "../types/index";

export default class Cell {
  data: Crossword;
  setData: (data: Crossword) => void;
  position: Coordinate;
  cell: CellAsType;

  constructor(
    data: Crossword,
    setData: (data: Crossword) => void,
    position: Coordinate
  ) {
    this.data = data;
    this.setData = setData;
    this.position = position;

    this.cell = data[position[1]][position[0]];
  }

  save() {
    let newData = [...this.data];

    newData[this.position[1]][this.position[0]] = this.cell;

    this.setData(newData);
  }

  set type(type) {
    let newContent = type === "empty" ? "" : this.cell.content;
    if (type === "character") {
      newContent = (this.cell.content || "").slice(0, 1).toUpperCase();
    }
    const newDirection = type === "clue" ? this.cell.direction : "";

    this.cell = {
      ...this.cell,
      type,
      content: newContent,
      direction: newDirection,
    };

    this.save();
  }

  set direction(direction: ClueDirection) {
    if (this.cell.type !== "clue") return;

    this.cell.direction = direction;
    this.save();
  }

  get type() {
    return this.cell.type;
  }

  appendContent(text: string) {
    switch (this.cell.type) {
      case "character":
        this.cell.content = text.slice(0, 1).toUpperCase();
        this.save();
        break;
      case "clue":
        this.cell.content = (this.cell.content || "") + text;
        this.save();
        break;
    }
  }

  removeLastCharacter() {
    this.cell.content = (this.cell.content || "").slice(0, -1);
    this.save();
  }
}
