import React from "react";
import { Cell, CellType } from "../types/index";
import CellGrid from "./CellGrid";
import ClueDirection from "./ClueDirection";
import FittedText from "./FittedText";

const backgroundColor = (
  cellType: CellType,
  isEmpty: boolean,
  isLonely: boolean,
  includeSolution: boolean
) => {
  switch (cellType) {
    case "empty":
      return "black";
    case "clue":
      return "#f2f2f2";
    default:
      if (isEmpty) {
        return "rgba(255, 0, 0, 0.1)";
      } else if (isLonely) {
        return "rgba(0, 0, 255, 0.1)";
      }

      if (includeSolution) {
        return "transparent";
      } else {
        // Required for rendering the SVG in Inkscape. Otherwise, the background is drawn over other elements.
        return "none";
      }
  }
};

const cellPadding = 2;

type Props = {
  isEmpty: boolean;
  isLonely: boolean;
  cellSelected: (column: number, row: number) => void;
  cell: Cell;
  x: number;
  y: number;
  width: number;
  height: number;
  columnIndex: number;
  rowIndex: number;
  includeSolution: boolean;
  print: boolean;
};

const CrosswordCell = ({
  isEmpty,
  isLonely,
  cellSelected,
  cell,
  x,
  y,
  width,
  height,
  columnIndex,
  rowIndex,
  includeSolution,
  print,
}: Props) => (
  <g className="cell" onClick={() => cellSelected(columnIndex, rowIndex)}>
    <CellGrid
      x={x}
      y={y}
      width={width}
      height={height}
      backgroundColor={backgroundColor(
        cell.type,
        isEmpty,
        isLonely,
        includeSolution
      )}
    />
    {cell.content && (cell.type !== "character" || includeSolution) && (
      <FittedText
        x={x + cellPadding}
        y={y + cellPadding}
        width={width - 2 * cellPadding}
        height={height - 2 * cellPadding}
        text={cell.content}
        print={print}
      />
    )}
    {cell.direction && (
      <ClueDirection
        cellX={x}
        cellY={y}
        width={width}
        height={height}
        direction={cell.direction}
      />
    )}
  </g>
);

export default CrosswordCell;
