import React from "react";

const directionArrowSize = 5;

type ArrowProps = {
  startArrowX: number;
  startArrowY: number;
  fill?: string;
  fillOpacity?: number;
};

export const ArrowRight = ({
  startArrowX,
  startArrowY,
  fill,
  fillOpacity,
}: ArrowProps) => (
  <path
    d={`M ${startArrowX},${startArrowY - directionArrowSize / 2} L ${
      startArrowX + directionArrowSize
    },${startArrowY} L ${startArrowX},${
      startArrowY + directionArrowSize / 2
    } Z`}
    fill={fill || "black"}
    fillOpacity={fillOpacity || 1.0}
  />
);

export const ArrowDown = ({
  startArrowX,
  startArrowY,
  fill,
  fillOpacity,
}: ArrowProps) => (
  <path
    d={`M ${
      startArrowX + directionArrowSize / 2
    },${startArrowY} L ${startArrowX},${startArrowY + directionArrowSize} L ${
      startArrowX - directionArrowSize / 2
    },${startArrowY} Z`}
    fill={fill || "black"}
    fillOpacity={fillOpacity || 1.0}
  />
);
