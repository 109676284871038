import React, { useRef } from "react";
import AlignJustifyIcon from "../assets/AlignJustifyIcon";
import { Word } from "../types/index";

type Props = {
  addWordlist: (fileName: string, words: Word[]) => void;
};

const UploadWordList = ({ addWordlist }: Props) => {
  const parseWordlist = (fileName: string, reader: FileReader) => () => {
    const fileContent = reader.result;

    if (fileContent === "string") {
      const words = fileContent.match(/[^\r\n]+/g);

      if (words) {
        addWordlist(fileName, words);
      }
    } else {
      throw new Error("File content is not a string");
    }
  };
  const uploadWordlist = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = parseWordlist(file.name, reader);
    reader.readAsText(file);
  };

  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <button
        title="Load a wordlist"
        onClick={() => fileInput.current && fileInput.current.click()}
      >
        <AlignJustifyIcon />
      </button>
      <input
        type="file"
        onChange={(event) => {
          if (event.currentTarget.files && event.currentTarget.files[0]) {
            uploadWordlist(event.currentTarget.files[0]);
          }
        }}
        style={{ display: "none" }}
        ref={fileInput}
      />
    </>
  );
};

export default UploadWordList;
