import React from "react";

const KeyboardShortcutsHelp = () => (
  <div style={{ fontSize: "0.6rem", marginBottom: "0.75rem" }}>
    <div>
      change cell type:
      <kbd>e</kbd> empty
      <kbd>w</kbd> character
      <kbd>r</kbd> clue
    </div>
    <div>
      change clue direction:
      <kbd>p</kbd> east
      <kbd>u</kbd> south
      <kbd>o</kbd> east and then down
      <kbd>y</kbd> west and then down
    </div>
    <div>
      movement:
      <kbd>k</kbd> / <kbd>up</kbd>,<kbd>j</kbd> / <kbd>down</kbd>,<kbd>l</kbd> /{" "}
      <kbd>right</kbd>,<kbd>h</kbd> / <kbd>left</kbd>
    </div>
    <div>
      <kbd>d</kbd> toggle writing direction
      <kbd>x</kbd> / <kbd>backspace</kbd> remove a character
    </div>
    <div>
      <kbd>i</kbd> enter input mode
      <kbd>esc</kbd> enter command mode
    </div>
  </div>
);

export default KeyboardShortcutsHelp;
