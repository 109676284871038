import { Coordinate, Crossword } from "../types/index";

export default class GridPosition {
  data: Crossword;
  position: Coordinate;

  constructor(data: Crossword, position: Coordinate) {
    this.data = data;
    this.position = position;
  }

  move(columns: number, rows: number) {
    const newPosition: Coordinate = [
      this.position[0] + columns,
      this.position[1] + rows,
    ];

    if (this.exists(newPosition)) {
      this.position = newPosition;
      return true;
    } else {
      return false;
    }
  }

  exists(position: Coordinate) {
    if (!position) return false;

    const column = position[0];
    const row = position[1];

    return this.data[row] && this.data[row][column];
  }

  isValid() {
    return this.exists(this.position);
  }

  get cellType() {
    return this.data[this.position[1]][this.position[0]].type;
  }
}
