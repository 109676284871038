import { ClueDirection, Coordinate, Crossword } from "../types/index";
import GridPosition from "./gridPosition";

const getFirstCharacterPosition = (
  data: Crossword,
  position: Coordinate
): Coordinate | null => {
  const cell = data[position[1]][position[0]];

  switch (cell.type) {
    case "character":
      return position;
    case "empty":
      return position;
    case "clue":
      switch (cell.direction) {
        case "n":
          return [position[0], position[1] - 1];
        case "ne":
          return [position[0], position[1] - 1];
        case "nw":
          return [position[0], position[1] - 1];
        case "e":
          return [position[0] + 1, position[1]];
        case "w":
          return [position[0] - 1, position[1]];
        case "s":
          return [position[0], position[1] + 1];
        case "se":
          return [position[0], position[1] + 1];
        case "sw":
          return [position[0], position[1] + 1];
        case "wd":
          return [position[0] - 1, position[1]];
        case "ed":
          return [position[0] + 1, position[1]];
      }
  }

  return null;
};

const getCoordinates = (
  data: Crossword,
  firstCoordinate: Coordinate,
  direction: ClueDirection
): Coordinate[] => {
  if (!direction) {
    return [];
  }

  let directionVector;

  switch (direction) {
    case "n":
      directionVector = [0, -1];
      break;
    case "ne":
      directionVector = [1, 0];
      break;
    case "e":
      directionVector = [1, 0];
      break;
    case "se":
      directionVector = [1, 0];
      break;
    case "s":
      directionVector = [0, 1];
      break;
    case "sw":
      directionVector = [-1, 0];
      break;
    case "w":
      directionVector = [-1, 0];
      break;
    case "nw":
      directionVector = [-1, 0];
      break;
    case "wd":
      directionVector = [0, 1];
      break;
    case "ed":
      directionVector = [0, 1];
      break;
    default:
      return [];
  }

  let coordinates = [];
  let currentPosition = firstCoordinate;

  while (true) {
    if (
      !data[currentPosition[1]] ||
      !data[currentPosition[1]][currentPosition[0]]
    ) {
      return coordinates;
    }

    const cell = data[currentPosition[1]][currentPosition[0]];

    if (cell.type !== "character") {
      return coordinates;
    } else {
      coordinates.push(currentPosition);
    }

    currentPosition = [
      currentPosition[0] + directionVector[0],
      currentPosition[1] + directionVector[1],
    ];
  }
};

const getWordOf = (data: Crossword, position: Coordinate) => {
  const cell = new GridPosition(data, position);

  if (!cell.isValid() || cell.cellType !== "clue") {
    return "";
  }

  const firstCoordinate = getFirstCharacterPosition(data, position);
  const direction = data[position[1]][position[0]].direction;

  if (!firstCoordinate || !direction) return "";

  const coordinates = getCoordinates(data, firstCoordinate, direction);

  return coordinates
    .map((coordinate) => data[coordinate[1]][coordinate[0]].content || "-")
    .join("");
};

const setWord = (
  data: Crossword,
  setData: (data: Crossword) => void,
  position: Coordinate,
  word: string
) => {
  let currentCell = data[position[1]][position[0]];

  if (!position || currentCell.type !== "clue") {
    return;
  }

  const firstCoordinate = getFirstCharacterPosition(data, position);
  const direction = data[position[1]][position[0]].direction;

  if (!firstCoordinate || !direction) return;

  const coordinates = getCoordinates(data, firstCoordinate, direction);

  let offset = 0;
  coordinates.forEach((coordinate) => {
    let cell = data[coordinate[1]][coordinate[0]];
    data[coordinate[1]][coordinate[0]] = {
      ...cell,
      content: word.charAt(offset).toUpperCase(),
    };
    offset++;
  });

  setData([...data]);
};

export { getFirstCharacterPosition, getCoordinates, getWordOf, setWord };
