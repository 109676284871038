export type CellType = "character" | "empty" | "clue";

const clueDirections: readonly string[] = [
  "n",
  "ne",
  "nw",
  "e",
  "w",
  "s",
  "se",
  "sw",
  "wd",
  "ed",
] as const;
export type ClueDirection = typeof clueDirections[number];

export const isClueDirection = (
  direction: unknown
): direction is ClueDirection =>
  typeof direction === "string" && clueDirections.includes(direction);

export type Cell = {
  type: CellType;
  direction?: ClueDirection;
  content?: string;
};

export type Crossword = Cell[][];

export type Coordinate = [number, number];

export enum Mode {
  Command = "COMMAND",
  Input = "INPUT",
}

export enum WRITING_DIRECTION {
  horizontal = "HORIZONTAL",
  vertical = "VERTICAL",
}

export type Word = string;

export type WordListPerCharacter = {
  [characterCount: number]: Word[];
};

export type WordLists = {
  [fileName: string]: WordListPerCharacter;
};
