import React from "react";
import { ClueDirection } from "../types/index";
import { ArrowRight, ArrowDown } from "./Arrows";

const directionLinePadding = 5;

type Props = {
  cellX: number;
  cellY: number;
  width: number;
  height: number;
  direction: ClueDirection;
};

const ClueDirection = ({ cellX, cellY, width, height, direction }: Props) => {
  switch (direction) {
    case "ne": {
      const startX = cellX + width / 2;
      const startY = cellY;

      const startArrowX = startX + directionLinePadding;
      const startArrowY = startY - directionLinePadding;

      return (
        <g>
          <path
            d={`M ${startX},${startY} V ${startArrowY} H ${startArrowX}`}
            fill="none"
            stroke="black"
            strokeWidth="1"
            shapeRendering="crispEdges"
          />
          <ArrowRight startArrowX={startArrowX} startArrowY={startArrowY} />
        </g>
      );
    }
    case "e":
      return (
        <ArrowRight
          startArrowX={cellX + width}
          startArrowY={cellY + height / 2}
        />
      );
    case "se": {
      const startX = cellX + width / 2;
      const startY = cellY + height;

      const startArrowX = startX + directionLinePadding;
      const startArrowY = startY + directionLinePadding;

      return (
        <g>
          <path
            d={`M ${startX},${startY} V ${startArrowY} H ${startArrowX}`}
            fill="none"
            stroke="black"
            strokeWidth="1"
            shapeRendering="crispEdges"
          />
          <ArrowRight startArrowX={startArrowX} startArrowY={startArrowY} />
        </g>
      );
    }
    case "s":
      return (
        <ArrowDown
          startArrowX={cellX + width / 2}
          startArrowY={cellY + height}
        />
      );
    case "ed": {
      const startX = cellX + width;
      const startY = cellY + height / 2;

      const startArrowX = startX + directionLinePadding;
      const startArrowY = startY + directionLinePadding;

      return (
        <g>
          <path
            d={`M ${startX},${startY} H ${startArrowX} V ${startArrowY}`}
            fill="none"
            stroke="black"
            strokeWidth="1"
            shapeRendering="crispEdges"
          />
          <ArrowDown startArrowX={startArrowX} startArrowY={startArrowY} />
        </g>
      );
    }
    case "wd": {
      const startX = cellX;
      const startY = cellY + height / 2;

      const startArrowX = startX - directionLinePadding;
      const startArrowY = startY + directionLinePadding;

      return (
        <g>
          <path
            d={`M ${startX},${startY} H ${startArrowX} V ${startArrowY}`}
            fill="none"
            stroke="black"
            strokeWidth="1"
            shapeRendering="crispEdges"
          />
          <ArrowDown startArrowX={startArrowX} startArrowY={startArrowY} />
        </g>
      );
    }
    default:
      return null;
  }
};

export default ClueDirection;
