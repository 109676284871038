import React from "react";

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex?: number;
  OUTLINE_WIDTH: number;
};

const CellOutline = ({ x, y, width, height, zIndex, OUTLINE_WIDTH }: Props) => (
  <path
    d={`M ${x - OUTLINE_WIDTH / 2},${y - OUTLINE_WIDTH / 2} L ${
      x + width + OUTLINE_WIDTH / 2
    },${y - OUTLINE_WIDTH / 2} L ${x + width + OUTLINE_WIDTH / 2},${
      y + height + OUTLINE_WIDTH / 2
    } L ${x - OUTLINE_WIDTH / 2},${y + height + OUTLINE_WIDTH / 2} L ${
      x - OUTLINE_WIDTH / 2
    },${y - OUTLINE_WIDTH}`}
    fill="transparent"
    stroke="rgba(51, 51, 204, 0.5)"
    strokeWidth="3"
    shapeRendering="crispEdges"
    style={{ zIndex: zIndex ?? 1 }}
  />
);

export default CellOutline;
