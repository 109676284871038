import React from "react";
import { Coordinate, Crossword, WRITING_DIRECTION } from "../types/index";
import CellOutline from "./CellOutline";
import CrosswordCell from "./CrosswordCell";
import WritingDirectionIndicator from "./WritingDirectionIndicator";

type Props = {
  data: Crossword;
  cellWidth: number;
  OUTLINE_WIDTH: number;
  emptyCoordinates: Coordinate[];
  lonelyCoordinates: Coordinate[];
  currentCell: Coordinate;
  writingDirection?: WRITING_DIRECTION;
  openDetails: (column: number, row: number) => void;
  print: boolean;
  includeSolution: boolean;
};

const CrosswordSvg = ({
  data,
  cellWidth,
  OUTLINE_WIDTH,
  emptyCoordinates,
  lonelyCoordinates,
  currentCell,
  writingDirection,
  openDetails,
  print,
  includeSolution,
}: Props) => (
  <svg
    width={data[0].length * cellWidth + 1 + 2 * OUTLINE_WIDTH}
    height={data.length * cellWidth + 1 + 2 * OUTLINE_WIDTH}
    fill="white"
  >
    {data.map((row, rowIndex) =>
      row.map((cell, columnIndex) => (
        <CrosswordCell
          key={`cell-${columnIndex}`}
          isEmpty={
            emptyCoordinates.filter(
              (coordinate) =>
                coordinate[0] === columnIndex && coordinate[1] === rowIndex
            ).length > 0
          }
          isLonely={
            lonelyCoordinates.filter(
              (coordinate) =>
                coordinate[0] === columnIndex && coordinate[1] === rowIndex
            ).length > 0
          }
          cellSelected={() => openDetails(columnIndex, rowIndex)}
          cell={cell}
          x={columnIndex * cellWidth + OUTLINE_WIDTH}
          y={rowIndex * cellWidth + OUTLINE_WIDTH}
          width={cellWidth}
          height={cellWidth}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          includeSolution={includeSolution}
          print={print}
        />
      ))
    )}

    {currentCell && !print && (
      <CellOutline
        x={currentCell[0] * cellWidth + OUTLINE_WIDTH}
        y={currentCell[1] * cellWidth + OUTLINE_WIDTH}
        width={cellWidth}
        height={cellWidth}
        OUTLINE_WIDTH={OUTLINE_WIDTH}
      />
    )}
    {currentCell && !print && writingDirection && (
      <WritingDirectionIndicator
        writingDirection={writingDirection}
        currentCell={currentCell}
        cellWidth={cellWidth}
        OUTLINE_WIDTH={OUTLINE_WIDTH}
      />
    )}
  </svg>
);

export default CrosswordSvg;
