import React, { useState } from "react";
import CrosswordGrid from "./CrosswordGrid";
import KeyboardShortcutsHelp from "./KeyboardShortcutsHelp";
import Settings from "./Settings";
import WordLists from "./WordLists";
import {
  createGrid,
  resizeGrid,
  getColumns,
  getRows,
  createCell,
} from "../models/grid";
import {
  getFirstCharacterPosition,
  getCoordinates,
  getWordOf,
  setWord,
} from "../models/word";
import Header from "./Header";
import {
  Cell,
  Coordinate,
  Crossword,
  Mode,
  Word,
  WordListPerCharacter,
  WordLists as WordListsType,
} from "../types/index";

const getEmptyCoordinates = (data: Crossword): Coordinate[] => {
  let characterCoordinates: Coordinate[] = data
    .map((row, rowIndex) =>
      row.map((_cell, columnIndex) => [columnIndex, rowIndex] as Coordinate)
    )
    .flat()
    .filter(
      (coordinate) => data[coordinate[1]][coordinate[0]].type === "character"
    );

  data.forEach((row, rowIndex) =>
    row.forEach((cell, columnIndex) => {
      if (cell.type === "clue") {
        const firstCharacterPosition = getFirstCharacterPosition(data, [
          columnIndex,
          rowIndex,
        ]);

        if (firstCharacterPosition && cell.direction) {
          getCoordinates(data, firstCharacterPosition, cell.direction).forEach(
            (wordCoordinate) => {
              characterCoordinates = characterCoordinates.filter(
                (coordinate) =>
                  !(
                    coordinate[0] === wordCoordinate[0] &&
                    coordinate[1] === wordCoordinate[1]
                  )
              );
            }
          );
        }
      }
    })
  );

  return characterCoordinates;
};

const Crossword = () => {
  const [data, setData] = useState(createGrid(20, 29, createCell));
  const [currentCell, setCurrentCell] = useState<Coordinate>([0, 0]);
  const [wordLists, setWordLists] = useState<WordListsType>({});

  const addWordlist = (fileName: string, words: Word[]) => {
    const reducer = (accumulator: WordListPerCharacter, word: Word) => {
      const cleanedWord = word.trim().toUpperCase();
      const characterCount = cleanedWord.length;

      if (!accumulator[characterCount].includes(cleanedWord)) {
        accumulator[characterCount].push(cleanedWord);
      }

      return accumulator;
    };

    let cleanedWords: WordListPerCharacter = words.reduce(reducer, {});

    cleanedWords = Object.keys(cleanedWords).reduce<WordListPerCharacter>(
      (accumulator, key) => {
        const characterCount = parseInt(key);

        accumulator[characterCount] = cleanedWords[characterCount].sort();

        return accumulator;
      },
      {}
    );

    wordLists[fileName] = cleanedWords;

    setWordLists({ ...wordLists });
  };

  const openDetails = (column: number, row: number) => {
    setCurrentCell([column, row]);
  };

  const updateCell = (position: Coordinate, newCell: Cell) => {
    data[position[1]][position[0]] = newCell;
    setData([...data]);
  };

  const getLonelyHighlights = () => {
    let scores = createGrid(data[0].length, data.length, () => 0);

    data.forEach((row, rowIndex) => {
      row.forEach((cell, columnIndex) => {
        if (cell.type === "clue") {
          const firstCharacterPosition = getFirstCharacterPosition(data, [
            columnIndex,
            rowIndex,
          ]);

          if (firstCharacterPosition && cell.direction) {
            const wordCoordinates = getCoordinates(
              data,
              firstCharacterPosition,
              cell.direction
            );

            wordCoordinates.forEach(
              ([column, row]) => (scores[row][column] += 1)
            );
          }
        }
      });
    });

    // TODO: score == 0 would be empty cells, so we could use this same algorithm for those as well

    let lonelyCoordinates: Coordinate[] = [];

    scores.forEach((row, rowIndex) => {
      row.forEach((score, columnIndex) => {
        if (score == 1) {
          lonelyCoordinates.push([columnIndex, rowIndex]);
        }
      });
    });

    return lonelyCoordinates;
  };

  const [showSettings, setShowSettings] = useState(false);
  const [highlightEmpty, setHighlightEmpty] = useState(false);
  const [highlightLonely, setHighlightLonely] = useState(false);

  const lonelyCoordinates = highlightLonely ? getLonelyHighlights() : [];

  const MODE = Mode;
  const [mode, setMode] = useState(MODE.Command);
  const [keyboardShortcuts, setKeyboardShortcuts] = useState(false);
  const [cellWidth, setCellWidth] = useState(32);

  const isValidCell = (column: number, row: number) =>
    data[row] && data[row][column];

  return (
    <div className="app">
      <Header
        setShowSettings={setShowSettings}
        showSettings={showSettings}
        setKeyboardShortcuts={setKeyboardShortcuts}
        keyboardShortcuts={keyboardShortcuts}
        currentCell={currentCell}
        mode={mode}
        data={data}
        updateCell={updateCell}
        currentWord={getWordOf(data, currentCell)}
        addWordlist={addWordlist}
        setHighlightEmpty={setHighlightEmpty}
        setHighlightLonely={setHighlightLonely}
        setData={setData}
        cellWidth={cellWidth}
        openDetails={openDetails}
        highlightEmpty={highlightEmpty}
        highlightLonely={highlightLonely}
      />
      <main>
        <div>
          {showSettings && (
            <Settings
              columns={getColumns(data)}
              rows={getRows(data)}
              gridResizeRequested={(columns: number, rows: number) => {
                resizeGrid(data, setData, columns, rows);
                setCurrentCell([0, 0]);
              }}
              cellWidth={cellWidth}
              setCellWidth={(cellWidth: number) => setCellWidth(cellWidth)}
            />
          )}
        </div>
        {keyboardShortcuts && (
          <div className="no-print">
            <KeyboardShortcutsHelp />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <div>
            <CrosswordGrid
              data={data}
              openDetails={openDetails}
              currentCellPosition={currentCell}
              setData={(data: Crossword) => setData(data)}
              emptyCoordinates={highlightEmpty ? getEmptyCoordinates(data) : []}
              lonelyCoordinates={lonelyCoordinates}
              requestedMoveToCell={(column: number, row: number) => {
                if (isValidCell(column, row)) setCurrentCell([column, row]);
              }}
              mode={mode}
              MODE={MODE}
              setMode={setMode}
              cellWidth={cellWidth}
              isValidCell={isValidCell}
            />
          </div>
          <div className="no-print">
            <WordLists
              wordLists={wordLists}
              filter={currentCell && getWordOf(data, currentCell)}
              wordSelected={(event: React.FormEvent<HTMLSelectElement>) =>
                setWord(data, setData, currentCell, event.currentTarget.value)
              }
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Crossword;
