import React from "react";
import {
  Cell,
  ClueDirection,
  Coordinate,
  isClueDirection,
} from "../types/index";

type Props = {
  cell: Cell;
  position: Coordinate;
  updateCell: (position: Coordinate, newCell: Cell) => void;
  word: string;
};

const CrosswordCellDetails = ({ cell, position, updateCell, word }: Props) => {
  const setDirection = (event: React.FormEvent<HTMLSelectElement>) => {
    const newDirection = event.currentTarget.value;

    if (isClueDirection(newDirection)) {
      cell.direction = newDirection as ClueDirection;
      updateCell(position, cell);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        fontSize: "0.8rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <small>Type</small>
        </div>
        <div>{cell.type}</div>
      </div>
      {cell.type === "clue" && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <small>Direction</small>
            </div>
            <div>
              <select value={cell.direction || ""} onChange={setDirection}>
                <option value=""></option>
                <option value="n">N</option>
                <option value="ne">NE</option>
                <option value="e">E</option>
                <option value="se">SE</option>
                <option value="s">S</option>
                <option value="sw">SW</option>
                <option value="w">W</option>
                <option value="nw">NW</option>
                <option value="wd">WD</option>
                <option value="ed">ED</option>
              </select>
            </div>
          </div>
          <div style={{ fontSize: "2rem" }}>
            <strong>{word}</strong>
          </div>
        </>
      )}
    </div>
  );
};

export default CrosswordCellDetails;
