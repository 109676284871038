import React, { useEffect, useState } from "react";

const getTextSize = (inputText: string, fontSize = 16) => {
  const container = document.createElement("canvas");

  let text = inputText || "";
  text = text.toString();

  const context = container.getContext("2d");

  if (context) {
    context.font = `${fontSize}px normal sans-serif`;
    const size = context.measureText(text);

    return [
      size.width,
      size.actualBoundingBoxAscent + size.actualBoundingBoxDescent,
    ];
  } else {
    /* if something goes wrong mounting the canvas, return an estimate calculated using
     * the backup ratio, the average open-sans font height-width ratio of 0.5
     */
    const fontSize = parseFloat(
      window.getComputedStyle(document.body).getPropertyValue("font-size")
    );
    const backupRatio = 0.5;

    return [fontSize * backupRatio * text.length, fontSize * 1.1];
  }
};

type TextMetric = {
  fontSize: number;
  lines: string[];
  boundingWidth: number;
  boundingHeight: number;
};

const wrapText = (text: string, width: number, height: number): TextMetric => {
  if (!text || text.length <= 0) {
    return {
      fontSize: 12,
      lines: [],
      boundingWidth: width,
      boundingHeight: height,
    };
  }

  const defaultFontSize = 16;

  const parts = text
    .split(" ")
    .map((part) => part.match(/.*?-|.+$/g))
    .flat()
    .filter(Boolean) as string[];

  const maxWidth = Math.max(
    ...parts.map((part) => getTextSize(part, defaultFontSize)[0])
  );
  const widthRatio = width / maxWidth;
  let possibleFontSize = widthRatio * defaultFontSize;
  const maxFontSize = possibleFontSize;

  let currentLineWidth = 0;
  let lines = [];
  let currentParts: string[] = [];

  parts.forEach((part) => {
    const wordWidth = getTextSize(part, possibleFontSize)[0];

    if (currentLineWidth + wordWidth > width) {
      if (currentParts.length > 0) {
        lines.push(currentParts.join(" "));
        currentParts = [];
        currentLineWidth = 0;
      }
      currentParts.push(part);
      currentLineWidth += wordWidth;
    } else {
      currentParts.push(part);
      // TODO: add width of a single whitespace here
      currentLineWidth += wordWidth;
    }
  });

  if (currentParts.length > 0) {
    lines.push(currentParts.join(" "));
  }

  let currentHeight = lines
    .map((line) => getTextSize(line, possibleFontSize)[1])
    .reduce((a, b) => a + b, 0);
  const lineHeight = parseFloat(
    window.getComputedStyle(document.body).getPropertyValue("line-height")
  );
  currentHeight = lines.length * possibleFontSize * (lineHeight || 1.2);

  let fontSize = possibleFontSize;
  fontSize = (height / currentHeight) * fontSize;
  if (fontSize > maxFontSize) fontSize = maxFontSize;

  const boundingWidth = Math.max(
    ...lines.map((line) => getTextSize(line, fontSize)[0])
  );
  const boundingHeight = lines.length * fontSize * (lineHeight || 1.2);

  return { fontSize, lines, boundingWidth, boundingHeight };
};

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  print: boolean;
};

const FittedText = ({ x, y, width, height, text, print }: Props) => {
  const [fittedData, setFittedData] = useState<TextMetric | null>(null);

  let renderedData: TextMetric | null = fittedData;

  if (print) {
    renderedData = wrapText(text, width, height);
  } else {
    useEffect(() => {
      const data = wrapText(text, width, height);

      setFittedData(data);
    }, [text]);
  }

  if (renderedData) {
    const { fontSize, lines, boundingWidth, boundingHeight } = renderedData;
    const baselineOffset = fontSize;

    return (
      <>
        {lines.map((line, index) => (
          <text
            key={index}
            x={x + (width - boundingWidth) / 2}
            y={
              y +
              index * fontSize +
              baselineOffset +
              (height - boundingHeight) / 2
            }
            style={{ font: `normal ${fontSize}px sans-serif` }}
            fill="black"
          >
            {line}
          </text>
        ))}
      </>
    );
  } else {
    return null;
  }
};

export default FittedText;
